<template>
  <div>
    <RegisterForm />
  </div>
</template>

<script>
import RegisterForm from '@/components/forms/RegisterForm.vue';

export default {
  components: {
    RegisterForm,
  },
};
</script>
