<template>
  <div>
    <Form
      class="space-y-6"
      :validation-schema="validationSchema"
      :initial-values="{ token }"
      dispatch="user/register"
    >
      <Field name="email" placeholder="Email" label="Email" />
      <Field name="firstName" placeholder="First Name" label="First Name" />
      <Field name="lastName" placeholder="Last Name" label="Last Name" />
      <Field
        name="phoneNumber"
        placeholder="Phone Number"
        label="Phone Number"
      />
      <Field
        type="password"
        name="password"
        placeholder="Password"
        label="Password"
      />
      <Field
        type="password"
        name="confirmPassword"
        placeholder="Confirm Password"
        label="Confirm Password"
      />
      <Field
        type="password"
        name="token"
        placeholder="Token"
        label="Token"
        readonly
      />
      <div class="flex justify-between items-center">
        <Button>Register</Button>
        <router-link to="/login" class="font-semibold text-sm text-blue-500">
          Login
        </router-link>
      </div>
    </Form>
  </div>
</template>

<script>
import { object, string, ref } from 'yup';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const {
      query: { token },
    } = route;

    const validationSchema = object({
      email: string().required().email(),
      firstName: string().required(),
      lastName: string().required(),
      phoneNumber: string().required(),
      password: string().min(8).required(),
      confirmPassword: string()
        .min(8)
        .required()
        .oneOf([ref('password'), null], 'passwords do not match.'),
      token: string().required(),
    });

    return {
      validationSchema,
      token,
    };
  },
};
</script>
